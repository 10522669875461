<template>
  <div class="vld-parent">
    <loading
      :height="height"
      :width="width"
      :opacity="opacity"
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :z-index="zindex"
    ></loading>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  /* eslint-disable */
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'loader': {
          }
        }
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  name: 'loader',
  data() {
    return {
      height: 65,
      width: 65,
      opacity: 0.8,
      zindex: 99999,
      loading: true,
      fullPage: true
    }
  },
  components: {
    Loading
  },
  methods: {}
}
</script> 
